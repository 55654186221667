.swipeable_tab {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.swipeable_tab .tab_item {
  color: var(--white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  padding-bottom: 12px;
  cursor: pointer;
  opacity: 0.5;
}

.swipeable_tab .tab_item > span {
  text-transform: capitalize;
}
.swipeable_tab .tab_item > span:hover {
  color: #e93249;
  transition: 0.2s ease-in-out;
}

.swipeable_tab .tab_item_active {
  border-bottom: #e93249 4px solid;
  font-weight: 700;
  padding-bottom: 8px;
  opacity: 1;
}
/* Tablet */
@media screen and (max-width: 1023px) {
  .swipeable_tab {
    column-gap: 32px;
  }
  .swipeable_tab .tab_item {
    font-size: 14px;
  }
}

/* Mobile */
@media screen and (max-width: 744px) {
  .swipeable_tab {
    column-gap: 24px;
  }
  .swipeable_tab .tab_item {
    font-size: 12px;
  }
}
